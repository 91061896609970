<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection :title="title[lang]"></TitleSection>
      </v-col>
    </v-row>

    <v-row v-if="user">
      <v-col lg="5" md="7" sm="12" class="mx-auto">
        <ProfileCard v-if="user" :user="user"></ProfileCard>
      </v-col>
      <v-col cols="12" lg="12">
        <v-tabs v-model="tab" centered>
          <v-tabs-slider color="tertiary"></v-tabs-slider>

          <v-tab key="profile"> {{ labels.profile[lang] }} </v-tab>
          <!-- <v-tab key="editProfile"> {{ labels.editProfile[lang] }} </v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="profile" class="d-flex justify-content-center">
            <v-card flat width="500">
              <v-card-text>
                <FormRegister :userProp="userEdited"></FormRegister>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="editProfile">
            <v-card flat>
              <v-card-text> </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col lg="12" md="12" sm="12" class="d-flex justify-content-center">
        <v-btn
          depressed
          dark
          color="tertiary"
          @click="
            $store.dispatch('changeAuthMenu', !$store.state.auth.authMenu);
            $store.dispatch('changeTabMenu', 'tab-login');
          "
          class="readable"
          >{{ btnLogin[lang] }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileCard from "@/components/cards/ProfileCard.vue";
import FormRegister from "@/components/forms/FormRegister";
import TitleSection from "@/components/titles/TitleSection.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ProfileCard,
    TitleSection,
    FormRegister,
  },
  data() {
    return {
      tab: "profile",
      title: {
        en: "My account",
        es: "Mi cuenta",
      },
      btnLogin: {
        en: "Login",
        es: "Ingresa con tu cuenta",
      },
      labels: {
        profile: {
          en: "My profile",
          es: "Mi perfíl",
        },
        editProfile: {
          en: "Edit profile",
          es: "Editar perfíl",
        },
      },
      userEdited: null,
    };
  },
  watch: {
    user(user) {
      if (this.user) {
        this.userEdited = {
          ...this.user,
          vUsuario: this.user.CORREO,
          vClave: "",
          vClave2: "",
          vNombres: this.user.NOMBRES,
          vApellidos: this.user.APELLIDOS,
          iTipoDocumento: parseFloat(this.user.TIPODOCUMENTO),
          vNumeroDocumento: this.user.NUMERODOCUMENTO,
          foto: "",
          idUsuario: this.user.IDUSUARIO,
          iOrigen: this.user.ORIGEN,
          result: "",
        };
      } 
    },
  },
  created() {
    if (this.user) {
      this.userEdited = {
        ...this.user,
        vUsuario: this.user.CORREO,
        vClave: "",
        vClave2: "",
        vNombres: this.user.NOMBRES,
        vApellidos: this.user.APELLIDOS,
        iTipoDocumento: parseFloat(this.user.TIPODOCUMENTO),
        vNumeroDocumento: this.user.NUMERODOCUMENTO,
        foto: "",
        idUsuario: this.user.IDUSUARIO,
        iOrigen: this.user.ORIGEN,
        result: "",
      };
    }
  },
  computed: {
    ...mapGetters({
      user: "getSessionUser",
      lang: "getLanguage",
      accTools: "getAccesibilityTool",
    }),
  },
  mounted() {
    this.accTools.restart(this.lang)
  }
};
</script>

<style>
</style>