<template>
  <v-card class="card-profile-user" elevation="0">
    <v-card-text >
      <div class="d-flex align-items-center justify-content-center">
        <v-avatar size="80px"  color="brown">
          <img
            v-if="user.URLFOTO"
            draggable="false"
            :alt="`AVATAR ${user.NOMBRES} ${user.APELLIDOS}`"
            :src="user.URLFOTO"
          />
          <span v-else class="white--text text-h5">{{ getInitials(user.NOMBRES, user.APELLIDOS) }}</span>
        </v-avatar>
        <div class="pl-3">
          <h5 class="user-names">{{ user.NOMBRES }} {{ user.APELLIDOS }}</h5>
          <p class="user-email mb-0">{{ user.CORREO }}</p>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        user: Object
    },
    methods: {
        getInitials(names = "", lastNames = "") {
            return `${names.split(" ")[0].charAt(0).toUpperCase()}${lastNames.split(" ")[0].charAt(0).toUpperCase()}`
        }
    }
};
</script>

<style lang="scss">

.card-profile-user {
    font-family: $primary-font;
    .user-names {
        font-size: 14px;
        font-weight: 600;
        color: #444;
        opacity: 1;
    }
    
    .user-email {
        font-size: 13px;
    }

}
</style>